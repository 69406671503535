import React, { useContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import AppRoutes from './handlers/appRoutes';
import GlobalWrapper from './handlers/GlobalWrapper';
import SplashScreen from './pages/trashkingApp/splashScreen/splashScreen';
import PopupsWrapper from './handlers/PopupsWrapper';

function App() {

  // const loadUser = () => {
  //   const userAccount = localStorage.getItem('activeAccountSustainablife');
  //   const accountData = JSON.parse(userAccount!);    
  //   return accountData === null ? undefined : accountData!.pkUser;    
  // }
  const splashed = sessionStorage.getItem('splashed');
  const [showSplashscreen, setShowSplashscreen] = useState(splashed === null ? true : false);

  useEffect(() => {
    setTimeout(() => {
      setShowSplashscreen(false);
    }, 4000);
  }, []);

  return showSplashscreen ? (
    <SplashScreen />
  ) : (
    <GlobalWrapper>
      <PopupsWrapper>
        <AppRoutes />
      </PopupsWrapper>
    </GlobalWrapper>
  );
}

export default App;
