class Settings {
    static api = {
      "path": process.env.NODE_ENV === 'production' ? 'https://magma.sustainablife.online' : 'http://emerald:8080'
    }

    static tempCategories =     
        [   
            { 'label': 'plastics (mix.)', 'value': '0' },
            { 'label': 'plastics (pet)', 'value': '1' },
            { 'label': 'plastics (hdpe)', 'value': '2' },
            { 'label': 'eWaste (boards)', 'value': '3' },
            { 'label': 'ewaste (wire)', 'value': '4' },
            { 'label': 'metal (copper)', 'value': '5' },
            { 'label': 'metal (iron)', 'value': '6' },
            { 'label': 'metal (mixed)', 'value': '7' },
            { 'label': 'wood', 'value': '8' },
            { 'label': 'clothes & textiles', 'value': '9' },
            { 'label': 'paper', 'value': '10' }
        ]
    static tempUnits = [
        { 'label': 'Kg.', 'value': '0' },
        { 'label': 'gr.', 'value': '1' },
        { 'label': 'Km.', 'value': '2' },
    ]
}

exports.Settings = Settings;